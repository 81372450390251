import React from 'react';

const Introduction = (props: any) => {
    return (
      <div
      style={{
        // width: "fit-content",
        // marginLeft: 90,
        marginTop: 50,
        // backgroundColor: "red",
        // flex: 1,
        display: "flex",
        flexDirection: "column",
        // alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#3A3A3A",
          display: "flex",
          // flexDirection: "row",
          alignItems: "center",
          height: 60,
          width: "fit-content",
          borderRadius: 20,
          // paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "#FFE600",
            width: 40,
            height: 40,
            borderRadius: 20,
            margin: 20,
          }}
        ></div>
        <span
          style={{
            fontSize: 18,
            fontWeight: "500",
            color: "#FFFFFF",
          }}
        >
          Next-Gen Digital Engineering Experiences
        </span>
      </div>
      <p
        style={{
          color: "white",
          fontSize: 60,
          fontWeight: "700",
        }}
      >
        lixone inc. <br />
        Building the Future,
        <br />
        One App at a Time
      </p>

      <p
        style={{
          color: "#C6C6C6",
          fontSize: 18,
          fontWeight: "500",
          maxWidth: 500,
        }}
      >
        At Lixone Inc., our dynamic team of developers, designers, and market researchers is passionately committed to crafting highly successful products. We invest our time and energy into staying ahead of the curve, constantly updating our solutions to embrace the latest technological advancements. With an unwavering dedication to excellence, we strive to deliver cutting-edge web and mobile applications that exceed expectations and drive our clients' success.
      </p>

      <br />
      <br />
      <a
        href="mailto:support@lixone.ca"
        style={{
          backgroundColor: "#6854FC",
          // height: 45,
          borderRadius: 20,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 20,
          paddingRight: 20,
          color: "#FFFFFF",
          fontSize: 18,
          fontWeight: "700",
        }}
      >
        Connect with us
      </a>
      {/* <a
        href="#"
        style={{
          color: "#6854FC",
          fontSize: 22,
          fontWeight: "600",
          marginLeft: 25,
        }}
      >
        Browes our works
      </a> */}
      <br />
    </div>
    )
}

export default Introduction;
