import React from 'react';

const Header = (props: any) => {
    return (
        <div className="headerDiv">


<a className="topHeaderLink" href="#" onClick={props.indexHandleClick}>
            <div className="headerLogo">
                <img className="logoIcon" src="lixoneLogo.png" />
                <span
                    style={{
                        fontWeight: "700",
                    }}
                >
                    Lixone inc.
                </span>
            </div>
            </a>
            <div className="headerMenu">
                <a className="topHeaderLink" href="#" onClick={props.termsHandleClick}>
                    Terms and Conditions
                </a>

                <a className="topHeaderLink" href="#" onClick={props.privacyHandleClick}>
                    Privacy Policy
                </a>
            </div>
        </div>
    )
}

export default Header;
