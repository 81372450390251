import React from 'react';

const Footer = (props: any) => {
    return (
        <div className="siteFooter">

        <div style={{
          display: "flex",
          flexDirection: "row",

        }}>
          <img width={100} src="lixoneLogo.png" />
        </div>
        <div className="footerMenu">



        <a className="topHeaderLink" href="#" onClick={props.termsHandleClick}>            
            Terms and Conditions
          </a>

          <a className="topHeaderLink" href="#" onClick={props.privacyHandleClick}>
            Privacy Policy
          </a>
        </div>
        <div className="socialsMenu">
          <a href="https://www.linkedin.com/company/lixone-inc">
            <div className="socialIcon">
              <img className="socialFrame" src="/assets/linkedinIcon.png" />
            </div>
          </a>
          <a href="https://ironclub.pro">
            <div className="socialIcon">
              <img className="ironSocialFrame" src="/assets/ironclubLogo.png" />
            </div>
          </a>

        </div>
        <div className="copyRight">Copyright © 2023 Lixone Inc. All rights reserved.</div>
        <br />
      </div>
    )
}

export default Footer;
