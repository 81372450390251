import React from 'react';
import "../Terms.css";

const Terms = (props: any) => {
    return (
        <div style={{
            padding: 20,
        }}>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">TERMS AND CONDITIONS</span></p>
            <p className="c3"><span className="c7 c15">Last updated May 25, 2023</span></p>
            <p className="c3"><span className="c8 c2">AGREEMENT TO OUR LEGAL TERMS</span></p>
            <p className="c3"><span className="c7">We are lixone Inc. (&quot;</span><span className="c2">Company</span><span
                className="c7">,&quot; &quot;</span><span className="c2">we</span><span className="c7">,&quot; &quot;</span><span
                    className="c2">us</span><span className="c7">,&quot; &quot;</span><span className="c2">our</span>
                    <span
                        className="c1">&quot;).</span></p>
            <p className="c3"><span className="c7">We operate the website </span><span className="c5"><a className="c6"
                href="https://www.google.com/url?q=http://lixone.ca&amp;sa=D&amp;source=editors&amp;ust=1685307591111182&amp;usg=AOvVaw2HEclQkYGrHOcBGtGBLmmI">http://lixone.ca</a></span><span
                    className="c7">&nbsp; (the &quot;</span><span className="c2">Site</span><span className="c7">&quot;), as well as any
                        other related products and services that refer or link to these legal terms (the &quot;</span><span
                            className="c2">Legal Terms</span><span className="c7">&quot;) (collectively, the &quot;</span><span
                                className="c2">Services</span><span className="c1">&quot;).</span></p>
            <p className="c3"><span className="c1">You can contact us by email at support@lixone.ca</span></p>
            <p className="c3"><span className="c7">These Legal Terms constitute a legally binding agreement made between you, whether
                personally or on behalf of an entity (&quot;</span><span className="c2">you</span><span className="c1">&quot;), and
                    lixone Inc., concerning your access to and use of the Services. You agree that by accessing the Services,
                    you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL
                    OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
                    IMMEDIATELY.</span></p>
            <p className="c3"><span className="c1">Supplemental terms and conditions or documents that may be posted on the Services
                from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole
                discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about
                any changes by updating the &quot;Last updated&quot; date of these Legal Terms, and you waive any right to
                receive specific notice of each such change. It is your responsibility to periodically review these Legal
                Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and
                to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the
                date such revised Legal Terms are posted.</span></p>
            <p className="c3"><span className="c1">The Services are intended for users who are at least 13 years of age. All users who
                are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission
                of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you
                must have your parent or guardian read and agree to these Legal Terms prior to you using the
                Services.</span></p>
            <p className="c3"><span className="c1">We recommend that you print a copy of these Legal Terms for your records.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">TABLE OF CONTENTS</span></p>
            <p className="c3"><span className="c1">1. OUR SERVICES</span></p>
            <p className="c3"><span className="c1">2. INTELLECTUAL PROPERTY RIGHTS</span></p>
            <p className="c3"><span className="c1">3. USER REPRESENTATIONS</span></p>
            <p className="c3"><span className="c1">4. PROHIBITED ACTIVITIES</span></p>
            <p className="c3"><span className="c1">5. USER GENERATED CONTRIBUTIONS</span></p>
            <p className="c3"><span className="c1">6. CONTRIBUTION LICENSE</span></p>
            <p className="c3"><span className="c1">7. SERVICES MANAGEMENT</span></p>
            <p className="c3"><span className="c1">8. PRIVACY POLICY</span></p>
            <p className="c3"><span className="c1">9. TERM AND TERMINATION</span></p>
            <p className="c3"><span className="c1">10. MODIFICATIONS AND INTERRUPTIONS</span></p>
            <p className="c3"><span className="c1">11. GOVERNING LAW</span></p>
            <p className="c3"><span className="c1">12. DISPUTE RESOLUTION</span></p>
            <p className="c3"><span className="c1">13. CORRECTIONS</span></p>
            <p className="c3"><span className="c1">14. DISCLAIMER</span></p>
            <p className="c3"><span className="c1">15. LIMITATIONS OF LIABILITY</span></p>
            <p className="c3"><span className="c1">16. INDEMNIFICATION</span></p>
            <p className="c3"><span className="c1">17. USER DATA</span></p>
            <p className="c3"><span className="c1">18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></p>
            <p className="c3"><span className="c1">19. CALIFORNIA USERS AND RESIDENTS</span></p>
            <p className="c3"><span className="c1">20. MISCELLANEOUS</span></p>
            <p className="c3"><span className="c1">21. CONTACT US</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c14">1.</span><span className="c7">&nbsp;</span><span className="c14">OUR SERVICES</span></p>
            <p className="c3"><span className="c1">The information provided when using the Services is not intended for distribution to
                or use by any person or entity in any jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any registration requirement within such
                jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations
                do so on their own initiative and are solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.</span></p>
            <p className="c3"><span className="c1">The Services are not tailored to comply with industry-specific regulations (Health
                Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA),
                etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not
                use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</span></p>
            <p className="c3"><span className="c8 c2">2. INTELLECTUAL PROPERTY RIGHTS</span></p>
            <p className="c3"><span className="c17 c2 c12">Our intellectual property</span></p>
            <p className="c3"><span className="c1">We are the owner or the licensee of all intellectual property rights in our Services,
                including all source code, databases, functionality, software, website designs, audio, video, text,
                photographs, and graphics in the Services (collectively, the &quot;Content&quot;), as well as the
                trademarks, service marks, and logos contained therein (the &quot;Marks&quot;).</span></p>
            <p className="c3"><span className="c1">Our Content and Marks are protected by copyright and trademark laws (and various
                other intellectual property rights and unfair competition laws) and treaties in the United States and around
                the world.</span></p>
            <p className="c3"><span className="c1">The Content and Marks are provided in or through the Services &quot;AS IS&quot; for
                your personal, non-commercial use or internal business purpose only.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c17 c2 c12">Your use of our Services</span></p>
            <p className="c3"><span className="c1">Subject to your compliance with these Legal Terms, including the &quot;PROHIBITED
                ACTIVITIES&quot; section below, we grant you a non-exclusive, non-transferable, revocable license to:</span>
            </p>
            <ul className="c16 lst-kix_list_1-0 start">
                <li className="c9 li-bullet-0"><span className="c1">access the Services; and</span></li>
                <li className="c3 c13 li-bullet-0"><span className="c1">download or print a copy of any portion of the Content to which
                    you have properly gained access.</span></li>
            </ul>
            <p className="c3"><span className="c1">solely for your personal, non-commercial use or internal business purpose.</span></p>
            <p className="c3"><span className="c1">Except as set out in this section or elsewhere in our Legal Terms, no part of the
                Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited
                for any commercial purpose whatsoever, without our express prior written permission.</span></p>
            <p className="c3"><span className="c7">If you wish to make any use of the Services, Content, or Marks other than as set out
                in this section or elsewhere in our Legal Terms, please address your request to: </span><span className="c5"><a
                    className="c6" href="mailto:support@ironclub.pro">support@</a></span><span className="c1">&nbsp;lixone.ca. If we
                        ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content,
                        you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any
                        copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our
                        Content.</span></p>
            <p className="c3"><span className="c1">We reserve all rights not expressly granted to you in and to the Services, Content,
                and Marks.</span></p>
            <p className="c3"><span className="c1">Any breach of these Intellectual Property Rights will constitute a material breach of
                our Legal Terms and your right to use our Services will terminate immediately.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c2 c12 c17">Your submissions</span></p>
            <p className="c3"><span className="c1">Please review this section and the &quot;PROHIBITED ACTIVITIES&quot; section
                carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have
                when you post or upload any content through the Services.</span></p>
            <p className="c3"><span className="c2 c12">Submissions:</span><span className="c7 c12">&nbsp;</span><span className="c1">By directly
                sending us any question, comment, suggestion, idea, feedback, or other information about the Services
                (&quot;Submissions&quot;), you agree to assign to us all intellectual property rights in such Submission.
                You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for
                any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</span></p>
            <p className="c3"><span className="c2 c12">You are responsible for what you post or upload:</span><span
                className="c7 c12">&nbsp;</span><span className="c1">By sending us Submissions through any part of the Services
                    you:</span></p>
            <ul className="c16 lst-kix_list_2-0 start">
                <li className="c9 li-bullet-0"><span className="c1">confirm that you have read and agree with our &quot;PROHIBITED
                    ACTIVITIES&quot; and will not post, send, publish, upload, or transmit through the Services any
                    Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
                    discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or
                    misleading;</span></li>
                <li className="c9 li-bullet-0"><span className="c1">to the extent permissible by applicable law, waive any and all moral
                    rights to any such Submission;</span></li>
                <li className="c9 li-bullet-0"><span className="c1">warrant that any such Submission are original to you or that you
                    have the necessary rights and licenses to submit such Submissions and that you have full authority to
                    grant us the above-mentioned rights in relation to your Submissions; and</span></li>
                <li className="c3 c13 li-bullet-0"><span className="c1">warrant and represent that your Submissions do not constitute
                    confidential information.</span></li>
            </ul>
            <p className="c3"><span className="c1">You are solely responsible for your Submissions and you expressly agree to reimburse
                us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third
                party&#39;s intellectual property rights, or (c) applicable law.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">3. USER REPRESENTATIONS</span></p>
            <p className="c3"><span className="c1">By using the Services, you represent and warrant that: (1) you have the legal
                capacity and you agree to comply with these Legal Terms; (2) you are not under the age of 13; (3) you are
                not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to
                use the Services; (4) you will not access the Services through automated or non-human means, whether through
                a bot, script or otherwise; (5) you will not use the Services for any illegal or unauthorized purpose; and
                (6) your use of the Services will not violate any applicable law or regulation. </span></p>
            <p className="c3"><span className="c1">If you provide any information that is untrue, inaccurate, not current, or
                incomplete, we have the right to suspend or terminate your account and refuse any and all current or future
                use of the Services (or any portion thereof).</span></p>
            <p className="c3"><span className="c2 c8">4. PROHIBITED ACTIVITIES</span></p>
            <p className="c3"><span className="c1">You may not access or use the Services for any purpose other than that for which we
                make the Services available. The Services may not be used in connection with any commercial endeavors except
                those that are specifically endorsed or approved by us.</span></p>
            <p className="c3"><span className="c1">As a user of the Services, you agree not to:</span></p>
            <ul className="c16 lst-kix_list_3-0 start">
                <li className="c0 li-bullet-0"><span className="c1">Systematically retrieve data or other content from the Services to
                    create or compile, directly or indirectly, a collection, compilation, database, or directory without
                    written permission from us.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Trick, defraud, or mislead us and other users, especially in any
                    attempt to learn sensitive account information such as user passwords.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Circumvent, disable, or otherwise interfere with security-related
                    features of the Services, including features that prevent or restrict the use or copying of any Content
                    or enforce limitations on the use of the Services and/or the Content contained therein.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
                    Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Use any information obtained from the Services in order to harass,
                    abuse, or harm another person.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Make improper use of our support services or submit false reports of
                    abuse or misconduct.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Use the Services in a manner inconsistent with any applicable laws
                    or regulations.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Engage in unauthorized framing of or linking to the Services.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Upload or transmit (or attempt to upload or to transmit) viruses,
                    Trojan horses, or other material, including excessive use of capital letters and spamming</span></li>
                <li className="c0 li-bullet-0"><span className="c1">(continuous posting of repetitive text), that interferes with any
                    party&#39;s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or
                    interferes with the use, features, functions, operation, or maintenance of the Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Engage in any automated use of the system, such as using scripts to
                    send comments or messages, or using any data mining, robots, or similar data gathering and extraction
                    tools.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Delete the copyright or other proprietary rights notice from any
                    Content.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Attempt to impersonate another user or person or use the username of
                    another user.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Upload or transmit (or attempt to upload or to transmit) any
                    material that acts as a passive or active information collection or transmission mechanism,</span></li>
                <li className="c0 li-bullet-0"><span className="c1">including without limitation, clear graphics interchange formats
                    (&quot;gifs&quot;), 1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to
                    as &quot;spyware&quot; or &quot;passive collection mechanisms&quot; or &quot;pcms&quot;).</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Interfere with, disrupt, or create an undue burden on the Services
                    or the networks or services connected to the Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Harass, annoy, intimidate, or threaten any of our employees or
                    agents engaged in providing any portion of the Services to you.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Attempt to bypass any measures of the Services designed to prevent
                    or restrict access to the Services, or any portion of the Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Copy or adapt the Services&#39; software, including but not limited
                    to Flash, PHP, HTML, JavaScript, or other code.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Except as permitted by applicable law, decipher, decompile,
                    disassemble, or reverse engineer any of the software comprising or in any way making up a part of the
                    Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Except as may be the result of standard search engine or Internet
                    browser usage, use, launch, develop, or distribute any automated system, including without limitation,
                    any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or
                    launch any unauthorized script or other software.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Use a buying agent or purchasing agent to make purchases on the
                    Services.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Make any unauthorized use of the Services, including collecting
                    usernames and/or email addresses of users by electronic or other means for the purpose of sending
                    unsolicited email, or creating user accounts by automated means or under false pretenses.</span></li>
                <li className="c11 li-bullet-0"><span className="c1">Use the Services as part of any effort to compete with us or
                    otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial
                    enterprise.</span></li>
            </ul>
            <p className="c3"><span className="c8 c2">5. USER GENERATED CONTRIBUTIONS</span></p>
            <p className="c3"><span className="c7">The Services does not offer users to submit or post content.</span></p>
            <p className="c3"><span className="c8 c2">6. CONTRIBUTION LICENSE</span></p>
            <p className="c3"><span className="c1">You and Services agree that we may access, store, process, and use any information
                and personal data that you provide and your choices (including settings).</span></p>
            <p className="c3"><span className="c1">By submitting suggestions or other feedback regarding the Services, you agree that we
                can use and share such feedback for any purpose without compensation to you.</span></p>
            <p className="c3"><span className="c8 c2">7. SERVICES MANAGEMENT</span></p>
            <p className="c3"><span className="c1">We reserve the right, but not the obligation, to: (1) monitor the Services for
                violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law
                enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to,
                limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or
                any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from
                the Services or otherwise disable all files and content that are excessive in size or are in any way
                burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights
                and property and to facilitate the proper functioning of the Services.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">8. PRIVACY POLICY</span></p>
            <p className="c3"><span className="c1">We care about data privacy and security. Please review our Privacy Policy:
                http://lixone .ca/privacy. By using the Services, you agree to be bound by our Privacy Policy posted on the
                Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the
                United States. If you access the Services from any other region of the world with laws or other requirements
                governing personal data collection, use, or disclosure that differ from applicable laws in the United
                States, then through your continued use of the Services, you are transferring your data to the United
                States, and you expressly consent to have your data transferred to and processed in the United States.
                Further, we do not knowingly accept, request, or solicit information from children or knowingly market to
                children. Therefore, in accordance with the U.S. Children&#39;s Online Privacy Protection Act, if we receive
                actual knowledge that anyone under the age of 13 has provided personal information to us without the
                requisite and verifiable parental consent, we will delete that information from the Services as quickly as
                is reasonably practical.</span></p>
            <p className="c3"><span className="c8 c2">9. TERM AND TERMINATION</span></p>
            <p className="c3"><span className="c1">These Legal Terms shall remain in full force and effect while you use the Services.
                WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION
                AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP
                ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION.
                WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
                INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</span></p>
            <p className="c3"><span className="c1">If we terminate or suspend your account for any reason, you are prohibited from
                registering and creating a new account under your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your
                account, we reserve the right to take appropriate legal action, including without limitation pursuing civil,
                criminal, and injunctive redress.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">10. MODIFICATIONS AND INTERRUPTIONS</span></p>
            <p className="c3"><span className="c1">We reserve the right to change, modify, or remove the contents of the Services at any
                time or for any reason at our sole discretion without notice. However, we have no obligation to update any
                information on our Services. We will not be liable to you or any third party for any modification, price
                change, suspension, or discontinuance of the Services.</span></p>
            <p className="c3"><span className="c1">We cannot guarantee the Services will be available at all times. We may experience
                hardware, software, or other problems or need to perform maintenance related to the Services, resulting in
                interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or
                otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no
                liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the
                Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be
                construed to obligate us to maintain and support the Services or to supply any corrections, updates, or
                releases in connection therewith.</span></p>
            <p className="c3"><span className="c8 c2">11. GOVERNING LAW</span></p>
            <p className="c3"><span className="c1">These Legal Terms shall be governed by and defined following the laws of Canada.
                Lixone Inc. and yourself irrevocably consent that the courts of Canada shall have exclusive jurisdiction to
                resolve any dispute which may arise in connection with these Legal Terms.</span></p>
            <p className="c3"><span className="c14">12. DISPUTE RESOLUTION</span></p>
            <p className="c3"><span className="c1">You agree to irrevocably submit all disputes related to these Legal Terms or the
                legal relationship established by these Legal Terms to the jurisdiction of the Canada courts. Lixone Inc.
                shall also maintain the right to bring proceedings as to the substance of the matter in the courts of the
                country where you reside or, if these Legal Terms are entered into in the course of your trade or
                profession, the state of your principal place of business.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">13. CORRECTIONS</span></p>
            <p className="c3"><span className="c1">There may be information on the Services that contains typographical errors,
                inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We
                reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information
                on the Services at any time, without prior notice.</span></p>
            <p className="c3"><span className="c8 c2">14. DISCLAIMER</span></p>
            <p className="c3"><span className="c1">THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE
                OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                SERVICES&#39; CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
                WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
                WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                APPROPRIATE.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">15. LIMITATIONS OF LIABILITY</span></p>
            <p className="c3"><span className="c1">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
                THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
                INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES,
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
                WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
                INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
                YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</span></p>
            <p className="c3"><span className="c8 c2">16. INDEMNIFICATION</span></p>
            <p className="c3"><span className="c1">You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss,
                damage, liability, claim, or demand, including reasonable attorneys&#39; fees and expenses, made by any
                third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any
                breach of your representations and warranties set forth in these Legal Terms; (4) your violation of the
                rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful
                act toward any other user of the Services with whom you connected via the Services. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter
                for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of
                such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is
                subject to this indemnification upon becoming aware of it.</span></p>
            <p className="c3"><span className="c8 c2">17. USER DATA</span></p>
            <p className="c3"><span className="c1">We will maintain certain data that you transmit to the Services for the purpose of
                managing the performance of the Services, as well as data relating to your use of the Services. Although we
                perform regular routine backups of data, you are solely responsible for all data that you transmit or that
                relates to any activity you have undertaken using the Services. You agree that we shall have no liability to
                you for any loss or corruption of any such data, and you hereby waive any right of action against us arising
                from any such loss or corruption of such data.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></p>
            <p className="c3"><span className="c1">Visiting the Services, sending us emails, and completing online forms constitute
                electronic communications. You consent to receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we provide to you electronically, via email and
                on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO
                THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
                waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
                jurisdiction which require an original signature or delivery or retention of non-electronic records, or to
                payments or the granting of credits by any means other than electronic means.</span></p>
            <p className="c3"><span className="c8 c2">19. CALIFORNIA USERS AND RESIDENTS</span></p>
            <p className="c3"><span className="c1">If any complaint with us is not satisfactorily resolved, you can contact the
                Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
                Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at
                (800) 952-5210 or (916) 445- 1254.</span></p>
            <p className="c3"><span className="c8 c2">20. MISCELLANEOUS</span></p>
            <p className="c3"><span className="c1">These Legal Terms and any policies or operating rules posted by us on the Services or
                in respect to the Services constitute the entire agreement and understanding between you and us. Our failure
                to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such
                right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or
                all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part
                of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or
                part of the provision is deemed severable from these Legal Terms and does not affect the validity and
                enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency
                relationship created between you and us as a result of these Legal Terms or use of the Services. You agree
                that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive
                any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing
                by the parties hereto to execute these Legal Terms.</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3"><span className="c8 c2">21. CONTACT US</span></p>
            <p className="c3"><span className="c1">In order to resolve a complaint regarding the Services or to receive further
                information regarding use of the Services, please contact us at:</span></p>
            <p className="c3"><span className="c1">Lixone Inc.</span></p>
            <p className="c3" id="h.gjdgxs"><span className="c7">support@lixone.ca</span></p>
            <p className="c3 c4"><span className="c1"></span></p>
            <p className="c3 c4"><span className="c1"></span></p>
        </div>
    )
}

export default Terms;
