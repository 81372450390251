import React, { useState } from 'react';
import "./App.css";
import Header from './components/Header';
import Introduction from './components/Introduction';
import OurWork from './components/OurWork';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Privacy from './components/Privacy';

function App() {
  const [showIndex, setShowIndex] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const indexHandleClick = () => {
    setShowIndex(true);
    setShowTerms(false);
    setShowPrivacy(false);
  };

  const termsHandleClick = () => {
    setShowIndex(false);
    setShowTerms(true);
    setShowPrivacy(false);
  };

  const privacyHandleClick = () => {
    setShowIndex(false);
    setShowTerms(false);
    setShowPrivacy(true);
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <Header
        termsHandleClick={termsHandleClick}
        privacyHandleClick={privacyHandleClick}
        indexHandleClick={indexHandleClick}
      />

      {showIndex && (
        <Introduction />
      )}

      {showIndex && (
        <OurWork />
      )}


      {showTerms && (
        <Terms />
      )}

      {showPrivacy && (
        <Privacy />
      )}

      <Footer
        termsHandleClick={termsHandleClick}
        privacyHandleClick={privacyHandleClick}
      />
    </div>

  );
}

export default App;
