import React from 'react';

const OurWork = (props: any) => {
    return (
      <div
      style={{
        flex: 1,
        backgroundColor: "#181818",
      }}
    >

      <p
        style={{
          alignSelf: "center",
          fontSize: 48,
          fontWeight: "700",
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        Our Works
      </p>
      
      <p
        style={{
          color: "#FFFFFF",
          fontSize: 18,
          fontWeight: "500",
          textAlign: "center",

        }}
      >
        From sleek and intuitive designs to robust functionality,<br />
        our works showcase our commitment to delivering exceptional <br />
        digital products. team members are mentioned in the project details.
      </p>
      <br />
      <div
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          className="tile"
          style={{
            margin: 40,
            padding: 50,
            borderRadius: 40,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 90,
              height: 90,
              borderRadius: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="assets/57125.png" />
          </div>
          <br />
          <br />

          <span
            style={{
              fontSize: 24,
              fontWeight: "600",
              color: "#FFFFFF",
            }}
          >
            Mobile App Development and Design
          </span>
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            Our expert team specializes in creating innovative and user-friendly mobile applications for iOS and Android platforms. From concept to launch, we ensure a seamless app development process, leveraging the latest technologies to deliver exceptional user experiences.


          </p>
        </div>

        <div
          className="tile"
          style={{
            margin: 40,
            padding: 50,
            borderRadius: 40,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 90,
              height: 90,
              borderRadius: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="assets/618872.png" />
          </div>
          <br />
          <br />

          <span
            style={{
              fontSize: 24,
              fontWeight: "600",
              color: "#FFFFFF",
            }}
          >
            Web App Development and Design
          </span>
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            With our web app development and design services, we bring your ideas to life on the web. Our skilled developers create responsive and scalable web applications that empower businesses and streamline processes. We focus on performance, security, and intuitive interfaces to maximize user engagement.
          </p>
        </div>
      </div>

      <div
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          className="tile"
          style={{
            margin: 40,
            padding: 50,
            borderRadius: 40,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 90,
              height: 90,
              borderRadius: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="assets/851251.png" />
          </div>
          <br />
          <br />

          <span
            style={{
              fontSize: 24,
              fontWeight: "600",
              color: "#FFFFFF",
            }}
          >
            Professional Website Development and Design
          </span>
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            Your website is the digital face of your business, and our professional website development and design services ensure an impactful online presence. We craft visually stunning and functional websites that are optimized for performance, search engines, and seamless user navigation, helping you reach and convert your target audience.
          </p>
        </div>

        <div
          className="tile"
          style={{
            margin: 40,
            padding: 50,
            borderRadius: 40,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 90,
              height: 90,
              borderRadius: 45,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="assets/614215.png" />
          </div>
          <br />
          <br />

          <span
            style={{
              fontSize: 24,
              fontWeight: "600",
              color: "#FFFFFF",
            }}
          >
            Creative Graphic Design Solutions
          </span>
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            Our creative graphic design solutions breathe life into your brand identity and marketing collateral. From logo design to marketing materials, our talented designers employ their artistic expertise to deliver visually captivating and cohesive designs that align with your brand's personality and effectively communicate your message.
          </p>
        </div>
      </div>
    </div>
    )
}

export default OurWork;
