import React from 'react';
import "../Privacy.css";

const Privacy = (props: any) => {
    return (
        <div style={{
            padding: 20,
        }}>
            <p className="c0"><span className="c3 c26">PRIVACY POLICY</span></p>
            <p className="c0"><span className="c3 c32">Last updated April 25, 2023</span></p>
            <p className="c0"><span>This privacy notice for Lixone Inc. (&quot;</span><span className="c3">Company</span><span>,&quot;
                &quot;</span><span className="c3">we</span><span>,&quot; &quot;</span><span className="c3">us</span><span>,&quot; or
                    &quot;</span><span className="c3">our</span><span>&quot;), describes how and why we might collect, store, use,
                        and/or share (&quot;</span><span className="c3">process</span><span>&quot;) your information when you use our
                            services (&quot;</span><span className="c3">Services</span><span className="c4">&quot;), such as when you:</span>
            </p>
            <ul className="c2 lst-kix_list_3-0 start">
                <li className="c1 li-bullet-0"><span className="c4">Visit our website at </span><span className="c14"><a className="c7"
                    href="https://www.google.com/url?q=http://lixone.ca&amp;sa=D&amp;source=editors&amp;ust=1685303039584249&amp;usg=AOvVaw1XXBCpkTGOqZuxnYLX9tUZ">http://lixone.ca</a></span><span
                        className="c4">, or any website of ours that links to this privacy notice </span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">Engage with us in other related ways, including any sales,
                    marketing, or events</span></li>
            </ul>
            <p className="c0"><span className="c3">Questions or concerns</span><span className="c4">? Reading this privacy notice will help
                you understand your privacy rights and choices. If you do not agree with our policies and practices, please
                do not use our Services. If you still have any questions or concerns, please contact us at
                support@lixone.ca</span></p>
            <p className="c0"><span className="c5 c3">SUMMARY OF KEY POINTS</span></p>
            <p className="c0"><span className="c3 c19">This summary provides key points from our privacy notice, but you can find out
                more details about any of these topics by clicking the link following each key point or by using our table
                of contents below to find the section you are looking for</span><span className="c4">.</span></p>
            <p className="c0"><span className="c3">What personal information do we process?</span><span className="c4">&nbsp;When you visit,
                use, or navigate our Services, we may process personal information depending on how you interact with lixone
                Inc. and the Services, the choices you make, and the products and features you use. Learn more about
                personal information you disclose to us.</span></p>
            <p className="c0"><span className="c3">Do we process any sensitive personal information?</span><span className="c4">&nbsp;We do
                not process sensitive personal information.</span></p>
            <p className="c0"><span className="c3">Do we receive any information from third parties?</span><span className="c4">&nbsp;We do
                not receive any information from third parties.</span></p>
            <p className="c0"><span className="c3">How do we process your information?</span><span className="c4">&nbsp;We process your
                information to provide, improve, and administer our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your information for other purposes with your
                consent. We process your information only when we have a valid legal reason to do so. Learn more about how
                we process your information.</span></p>
            <p className="c0"><span className="c3">In what situations and with which parties do we share personal
                information?</span><span className="c4">&nbsp;We may share information in specific situations and with specific
                    third parties. Learn more about when and with whom we share your personal information.</span></p>
            <p className="c0"><span className="c3">How do we keep your information safe?</span><span className="c4">&nbsp;We have
                organizational and technical processes and procedures in place to protect your personal information.
                However, no electronic transmission over the internet or information storage technology can be guaranteed to
                be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                parties will not be able to defeat our security and improperly collect, access, steal, or modify your
                information. Learn more about how we keep your information safe.</span></p>
            <p className="c0"><span className="c3">What are your rights? </span><span className="c4">Depending on where you are located
                geographically, the applicable privacy law may mean you have certain rights regarding your personal
                information. Learn more about your privacy rights.</span></p>
            <p className="c0"><span className="c3">How do you exercise your rights?</span><span className="c4">&nbsp;The easiest way to
                exercise your rights is by visiting http://lixone.ca/privacy or by contacting us. We will consider and act
                upon any request in accordance with applicable data protection laws.</span></p>
            <p className="c0"><span className="c4">Want to learn more about what Lixone Inc. does with any information we collect?
                Review the privacy notice in full.</span></p>
            <p className="c0"><span className="c5 c3">TABLE OF CONTENTS</span></p>
            <p className="c6"><span className="c4">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WHAT INFORMATION DO WE COLLECT?
            </span></p>
            <p className="c6"><span className="c4">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW DO WE PROCESS YOUR INFORMATION?
            </span></p>
            <p className="c6"><span className="c4">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WHAT LEGAL BASES DO WE RELY ON TO
                PROCESS YOUR PERSONAL INFORMATION?</span></p>
            <p className="c6"><span className="c4">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WHEN AND WITH WHOM DO WE SHARE YOUR
                PERSONAL INFORMATION? &nbsp;</span></p>
            <p className="c6"><span className="c4">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW LONG DO WE KEEP YOUR
                INFORMATION? </span></p>
            <p className="c6"><span className="c4">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW DO WE KEEP YOUR INFORMATION
                SAFE? </span></p>
            <p className="c6"><span className="c4">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DO WE COLLECT INFORMATION FROM
                MINORS? </span></p>
            <p className="c6"><span className="c4">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WHAT ARE YOUR PRIVACY RIGHTS?
            </span></p>
            <p className="c6"><span className="c4">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONTROLS FOR DO-NOT-TRACK FEATURES
            </span></p>
            <p className="c6"><span className="c4">10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DO CALIFORNIA RESIDENTS HAVE
                SPECIFIC PRIVACY RIGHTS? </span></p>
            <p className="c6"><span className="c4">11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DO VIRGINIA RESIDENTS HAVE
                SPECIFIC PRIVACY RIGHTS? </span></p>
            <p className="c6"><span className="c4">12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DO WE MAKE UPDATES TO THIS NOTICE?
            </span></p>
            <p className="c6"><span className="c4">13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW CAN YOU CONTACT US ABOUT THIS
                NOTICE? </span></p>
            <p className="c6"><span className="c4">14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW CAN YOU REVIEW, UPDATE, OR
                DELETE THE DATA WE COLLECT FROM YOU? </span></p>
            <p className="c0"><span className="c3 c22">1. WHAT INFORMATION DO WE COLLECT?</span><span className="c4">&nbsp;</span></p>
            <p className="c0"><span className="c3 c21">Personal information you disclose to us</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We collect personal information that you
                provide to us.</span></p>
            <p className="c0"><span className="c4">We collect personal information that you voluntarily provide to us when you register
                on the Services, express an interest in obtaining information about us or our products and Services, when
                you participate in activities on the Services, or otherwise when you contact us.</span></p>
            <p className="c0"><span className="c3">Sensitive Information</span><span className="c4">. We do not process sensitive
                information.</span></p>
            <p className="c0"><span className="c4">All personal information that you provide to us must be true, complete, and accurate,
                and you must notify us of any changes to such personal information.</span></p>
            <p className="c0"><span className="c5 c3">2. HOW DO WE PROCESS YOUR INFORMATION?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We process your information to provide,
                improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply
                with law. We may also process your information for other purposes with your consent.</span></p>
            <p className="c0"><span className="c12 c3">We process your personal information for a variety of reasons, depending on how
                you interact with our Services, including:</span></p>
            <ul className="c2 lst-kix_list_10-1 start">
                <li className="c0 c17 li-bullet-0"><span className="c12 c3">To save or protect an individual&#39;s vital
                    interest.</span><span className="c4">&nbsp;We may process your information when necessary to save or protect
                        an individual&#39;s vital interest, such as to prevent harm.</span></li>
            </ul>
            <p className="c0"><span className="c5 c3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We only process your personal information when
                we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable
                law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our
                contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</span></p>
            <p className="c0"><span className="c24 c3 c19">If you are located in the EU or UK, this section applies to you.</span></p>
            <p className="c0"><span className="c4">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the
                valid legal bases we rely on in order to process your personal information. As such, we may rely on the
                following legal bases to process your personal information:</span></p>
            <ul className="c2 lst-kix_list_12-1 start">
                <li className="c1 li-bullet-0"><span className="c12 c3">Consent.</span><span className="c4">&nbsp;We may process your
                    information if you have given us permission (i.e., consent) to use your personal information for a
                    specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your
                    consent.</span></li>
                <li className="c1 li-bullet-0"><span className="c12 c3">Legal Obligations.</span><span className="c4">&nbsp;We may process
                    your information where we believe it is necessary for compliance with our legal obligations, such as to
                    cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or
                    disclose your information as evidence in litigation in which we are involved.</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c3 c12">Vital Interests.</span><span className="c4">&nbsp;We may process
                    your information where we believe it is necessary to protect your vital interests or the vital interests
                    of a third party, such as situations involving potential threats to the safety of any person.</span>
                </li>
            </ul>
            <p className="c0"><span className="c3 c19 c24">If you are located in Canada, this section applies to you.</span></p>
            <p className="c0"><span className="c4">We may process your information if you have given us specific permission (i.e.,
                express consent) to use your personal information for a specific purpose, or in situations where your
                permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</span></p>
            <p className="c0"><span className="c4">In some exceptional cases, we may be legally permitted under applicable law to
                process your information without your consent, including, for example:</span></p>
            <ul className="c2 lst-kix_list_14-1 start">
                <li className="c8 li-bullet-1"><span className="c4">If collection is clearly in the interests of an individual and
                    consent cannot be obtained in a timely way</span></li>
                <li className="c8 li-bullet-1"><span className="c4">For investigations and fraud detection and prevention</span></li>
                <li className="c8 li-bullet-1"><span className="c4">For business transactions provided certain conditions are met</span>
                </li>
                <li className="c8 li-bullet-1"><span className="c4">If it is contained in a witness statement and the collection is
                    necessary to assess, process, or settle an insurance claim</span></li>
                <li className="c8 li-bullet-1"><span className="c4">For identifying injured, ill, or deceased persons and communicating
                    with next of kin</span></li>
                <li className="c8 li-bullet-2"><span className="c4">If we have reasonable grounds to believe an individual has been, is,
                    or may be victim of financial abuse</span></li>
                <li className="c8 li-bullet-2"><span className="c4">If it is reasonable to expect collection and use with consent would
                    compromise the availability or the accuracy of the information and the collection is reasonable for
                    purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a
                    province</span></li>
                <li className="c8 li-bullet-2"><span className="c4">If disclosure is required to comply with a subpoena, warrant, court
                    order, or rules of the court relating to the production of records</span></li>
                <li className="c8 li-bullet-1"><span className="c4">If it was produced by an individual in the course of their
                    employment, business, or profession and the collection is consistent with the purposes for which the
                    information was produced</span></li>
                <li className="c8 li-bullet-2"><span className="c4">If the collection is solely for journalistic, artistic, or literary
                    purposes</span></li>
                <li className="c0 c20 li-bullet-1"><span className="c4">If the information is publicly available and is specified by the
                    regulations</span></li>
            </ul>
            <p className="c0"><span className="c3 c22">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We may share information in specific
                situations described in this section and/or with the following third parties. </span></p>
            <p className="c0"><span className="c4">We may need to share your personal information in the following situations:</span>
            </p>
            <ul className="c2 lst-kix_list_20-0 start">
                <li className="c0 c17 li-bullet-0"><span className="c12 c3">Business Transfers.</span><span className="c4">&nbsp;We may
                    share or transfer your information in connection with, or during negotiations of, any merger, sale of
                    company assets, financing, or acquisition of all or a portion of our business to another company.</span>
                </li>
            </ul>
            <p className="c0"><span className="c5 c3">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span>
            </p>
            <p className="c0"><span className="c4">We will only keep your personal information for as long as it is necessary for the
                purposes set out in this privacy notice, unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
                your personal information for longer than the period of time in which users have an account with us.</span>
            </p>
            <p className="c0"><span className="c4">When we have no ongoing legitimate business need to process your personal
                information, we will either delete or anonymize such information, or, if this is not possible (for example,
                because your personal information has been stored in backup archives), then we will securely store your
                personal information and isolate it from any further processing until deletion is possible.</span></p>
            <p className="c0"><span className="c5 c3">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We aim to protect your personal information
                through a system of organizational and technical security measures.</span></p>
            <p className="c0"><span className="c4">We have implemented appropriate and reasonable technical and organizational security
                measures designed to protect the security of any personal information we process. However, despite our
                safeguards and efforts to secure your information, no electronic transmission over the Internet or
                information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information. Although we will do our best to protect your
                personal information, transmission of personal information to and from our Services is at your own risk. You
                should only access the Services within a secure environment.</span></p>
            <p className="c0"><span className="c5 c3">7. DO WE COLLECT INFORMATION FROM MINORS?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;We do not knowingly collect data from or
                market to children under 18 years of age.</span></p>
            <p className="c0"><span className="c4">We do not knowingly solicit data from or market to children under 18 years of age. By
                using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                minor and consent to such minor dependent&#39;s use of the Services. If we learn that personal information
                from users less than 18 years of age has been collected, we will deactivate the account and take reasonable
                measures to promptly delete such data from our records. If you become aware of any data we may have
                collected from children under age 18, please contact us at support@lixone.ca</span></p>
            <p className="c0"><span className="c5 c3">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;In some regions, such as the European Economic
                Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control
                over your personal information. You may review, change, or terminate your account at any time.</span></p>
            <p className="c0"><span className="c4">In some regions (like the EEA, UK, and Canada), you have certain rights under
                applicable data protection laws. These may include the right (i) to request access and obtain a copy of your
                personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your
                personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also
                have the right to object to the processing of your personal information. You can make such a request by
                contacting us by using the contact details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS
                NOTICE?&quot; below.</span></p>
            <p className="c0"><span className="c4">We will consider and act upon any request in accordance with applicable data
                protection laws.</span></p>
            <p className="c0"><span className="c4">If you are located in the EEA or UK and you believe we are unlawfully processing your
                personal information, you also have the right to complain to your Member State data protection authority or
                UK data protection authority.</span></p>
            <p className="c0"><span className="c4">If you are located in Switzerland, you may contact the Federal Data Protection and
                Information Commissioner.</span></p>
            <p className="c0"><span className="c3">Withdrawing your consent:</span><span className="c4">&nbsp;If we are relying on your
                consent to process your personal information, which may be express and/or implied consent depending on the
                applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at
                any time by contacting us by using the contact details provided in the section &quot;HOW CAN YOU CONTACT US
                ABOUT THIS NOTICE?&quot; below or updating your preferences.</span></p>
            <p className="c0"><span className="c4">However, please note that this will not affect the lawfulness of the processing
                before its withdrawal nor, when applicable law allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds other than consent.</span></p>
            <p className="c0"><span className="c4">If you have questions or comments about your privacy rights, you may email us at
                support@lixone.ca</span></p>
            <p className="c0"><span className="c3 c5">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
            <p className="c0"><span className="c4">Most web browsers and some mobile operating systems and mobile applications include a
                Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to
                have data about your online browsing activities monitored and collected. At this stage no uniform technology
                standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently
                respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be
                tracked online. If a standard for online tracking is adopted that we must follow in the future, we will
                inform you about that practice in a revised version of this privacy notice.</span></p>
            <p className="c0"><span className="c5 c3">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;Yes, if you are a resident of California, you
                are granted specific rights regarding access to your personal information.</span></p>
            <p className="c0"><span className="c4">California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot;
                law, permits our users who are California residents to request and obtain from us, once a year and free of
                charge, information about categories of personal information (if any) we disclosed to third parties for
                direct marketing purposes and the names and addresses of all third parties with which we shared personal
                information in the immediately preceding calendar year. If you are a California resident and would like to
                make such a request, please submit your request in writing to us using the contact information provided
                below.</span></p>
            <p className="c0"><span className="c4">If you are under 18 years of age, reside in California, and have a registered account
                with Services, you have the right to request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us using the contact information provided below
                and include the email address associated with your account and a statement that you reside in California. We
                will make sure the data is not publicly displayed on the Services, but please be aware that the data may not
                be completely or comprehensively removed from all our systems (e.g., backups, etc.).</span></p>
            <p className="c0"><span className="c21 c3">CCPA Privacy Notice</span></p>
            <p className="c0"><span className="c4">The California Code of Regulations defines a &quot;resident&quot; as:</span></p>
            <ol className="c2 lst-kix_list_21-0 start" start={1}>
                <li className="c1 li-bullet-0"><span className="c4">every individual who is in the State of California for other than a
                    temporary or transitory purpose and</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">every individual who is domiciled in the State of California who
                    is outside the State of California for a temporary or transitory purpose</span></li>
            </ol>
            <p className="c0"><span className="c4">All other individuals are defined as &quot;non-residents.&quot;</span></p>
            <p className="c0"><span className="c4">If this definition of &quot;resident&quot; applies to you, we must adhere to certain
                rights and obligations regarding your personal information.</span></p>
            <p className="c0"><span className="c12 c3">What categories of personal information do we collect?</span></p>
            <p className="c0"><span className="c4">We have collected the following categories of personal information in the past twelve
                (12) months:</span></p><a id="t.1880b28b622592a66358aa49261ada4ca05f3e95"></a><a id="t.0"></a>
            <table className="c30">
                <tr className="c11">
                    <td className="c29" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Category</span></p>
                    </td>
                    <td className="c31" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Examples</span></p>
                    </td>
                    <td className="c33" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Collected</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">A. Identifiers</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Contact details, such as real name, alias, postal address, telephone or
                            mobile contact number, unique personal identifier, online identifier, Internet Protocol address,
                            email address, and account name</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">B. Personal information categories listed in the California Customer
                            Records statute</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Name, contact information, education, employment, employment history, and
                            financial information</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">C. Protected classification characteristics under California or federal
                            law</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Gender and date of birth</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">D. Commercial information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Transaction information, purchase history, financial details, and payment
                            information</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">E. Biometric information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Fingerprints and voiceprints</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">F. Internet or other similar network activity</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Browsing history, search history, online behavior, interest data, and
                            interactions with our and other websites, applications, systems, and advertisements</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">G. Geolocation data</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Device location</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">H. Audio, electronic, visual, thermal, olfactory, or similar
                            information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Images and audio, video or call recordings created in connection with our
                            business activities</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">I. Professional or employment-related information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Business contact details in order to provide you our Services at a
                            business level or job title, work history, and professional qualifications if you apply for a
                            job with us</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">J. Education Information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Student records and directory information</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">K. Inferences drawn from other personal information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">Inferences drawn from any of the collected personal information listed
                            above to create a profile or summary about, for example, an individual&#39;s preferences and
                            characteristics</span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
                <tr className="c11">
                    <td className="c10" colSpan={1} rowSpan={1}>
                        <p className="c9"><span className="c4">L. Sensitive Personal Information</span></p>
                    </td>
                    <td className="c18" colSpan={1} rowSpan={1}>
                        <p className="c9 c23"><span className="c4"></span></p>
                    </td>
                    <td className="c13" colSpan={1} rowSpan={1}>
                        <p className="c15"><span className="c4">NO</span></p>
                    </td>
                </tr>
            </table>
            <p className="c0 c23"><span className="c4"></span></p>
            <p className="c0"><span className="c4">We may also collect other personal information outside of these categories through
                instances where you interact with us in person, online, or by phone or mail in the context of:</span></p>
            <ul className="c2 lst-kix_list_27-1 start">
                <li className="c1 li-bullet-0"><span className="c4">Receiving help through our customer support channels;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">Participation in customer surveys or contests; and</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">Facilitation in the delivery of our Services and to respond to
                    your inquiries.</span></li>
            </ul>
            <p className="c0"><span className="c12 c3">How do we use and share your personal information?</span></p>
            <p className="c0"><span className="c4">More information about our data collection and sharing practices can be found in this
                privacy notice.</span></p>
            <p className="c0"><span>You may contact us by email at </span><span className="c25"><a className="c7"
                href="mailto:support@lixone.ca">support@lixone.ca</a></span><span className="c4">&nbsp;, or by referring to
                    the contact details at the bottom of this document.</span></p>
            <p className="c0"><span className="c4">If you are using an authorized agent to exercise your right to opt out we may deny a
                request if the authorized agent does not submit proof that they have been validly authorized to act on your
                behalf.</span></p>
            <p className="c0"><span className="c12 c3">Will your information be shared with anyone else?</span></p>
            <p className="c0"><span className="c4">We may disclose your personal information with our service providers pursuant to a
                written contract between us and each service provider. Each service provider is a for-profit entity that
                processes the information on our behalf, following the same strict privacy protection obligations mandated
                by the CCPA.</span></p>
            <p className="c0"><span className="c4">We may use your personal information for our own business purposes, such as for
                undertaking internal research for technological development and demonstration. This is not considered to be
                &quot;selling&quot; of your personal information.</span></p>
            <p className="c0"><span className="c4">Lixone Inc. &nbsp;has not disclosed, sold, or shared any personal information to
                third parties for a business or commercial purpose in the preceding twelve (12) months. Lixone Inc.
                &nbsp;will not sell or share personal information in the future belonging to website visitors, users, and
                other consumers.</span></p>
            <p className="c0"><span className="c12 c3">Your rights with respect to your personal data</span></p>
            <p className="c0"><span className="c24 c28">Right to request deletion of the data &mdash; Request to delete</span></p>
            <p className="c0"><span className="c4">You can ask for the deletion of your personal information. If you ask us to delete
                your personal information, we will respect your request and delete your personal information, subject to
                certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
                her right to free speech, our compliance requirements resulting from a legal obligation, or any processing
                that may be required to protect against illegal activities.</span></p>
            <p className="c0"><span className="c24 c28">Right to be informed&mdash;Request to know</span></p>
            <p className="c0"><span className="c4">Depending on the circumstances, you have a right to know:</span></p>
            <ul className="c2 lst-kix_list_29-1 start">
                <li className="c1 li-bullet-0"><span className="c4">whether we collect and use your personal information;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">the categories of personal information that we collect;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">the purposes for which the collected personal information is
                    used;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">whether we sell or share personal information to third
                    parties;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">the categories of personal information that we sold, shared, or
                    disclosed for a business purpose;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">the categories of third parties to whom the personal information was
                    sold, shared, or disclosed for a business purpose;</span></li>
                <li className="c1 li-bullet-0"><span className="c4">the business or commercial purpose for collecting, selling, or
                    sharing personal information; and</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">the specific pieces of personal information we collected about
                    you.</span></li>
            </ul>
            <p className="c0"><span className="c4">In accordance with applicable law, we are not obligated to provide or delete consumer
                information that is de-identified in response to a consumer request or to re-identify individual data to
                verify a consumer request.</span></p>
            <p className="c0"><span className="c27">Right to Non-Discrimination for the Exercise of a Consumer&#39;s Privacy
                Rights</span><span className="c4">&nbsp;</span></p>
            <p className="c0"><span className="c4">We will not discriminate against you if you exercise your privacy rights. </span></p>
            <p className="c0"><span className="c24 c28">Right to Limit Use and Disclosure of Sensitive Personal Information </span></p>
            <p className="c0"><span className="c4">We do not process consumer&#39;s sensitive personal information.</span></p>
            <p className="c0"><span className="c24 c28">Verification process</span></p>
            <p className="c0"><span className="c4">Upon receiving your request, we will need to verify your identity to determine you
                are the same person about whom we have the information in our system. These verification efforts require us
                to ask you to provide information so that we can match it with information you have previously provided us.
                For instance, depending on the type of request you submit, we may ask you to provide certain information so
                that we can match the information you provide with the information we already have on file, or we may
                contact you through a communication method (e.g., phone or email) that you have previously provided to us.
                We may also use other verification methods as the circumstances dictate.</span></p>
            <p className="c0"><span className="c4">We will only use personal information provided in your request to verify your
                identity or authority to make the request. To the extent possible, we will avoid requesting additional
                information from you for the purposes of verification. However, if we cannot verify your identity from the
                information already maintained by us, we may request that you provide additional information for the
                purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such
                additionally provided information as soon as we finish verifying you.</span></p>
            <p className="c0"><span className="c24 c28">Other privacy rights</span></p>
            <ul className="c2 lst-kix_list_31-0 start">
                <li className="c1 li-bullet-0"><span className="c4">You may object to the processing of your personal
                    information.</span></li>
                <li className="c1 li-bullet-0"><span className="c4">You may request correction of your personal data if it is incorrect
                    or no longer relevant, or ask to restrict the processing of the information.</span></li>
                <li className="c1 li-bullet-0"><span className="c4">You can designate an authorized agent to make a request under the
                    CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they
                    have been validly authorized to act on your behalf in accordance with the CCPA.</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">You may request to opt out from future selling or sharing of
                    your personal information to third parties. Upon receiving an opt-out request, we will act upon the
                    request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request
                    submission.</span></li>
            </ul>
            <p className="c0"><span className="c5 c3">11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p>
            <p className="c0"><span className="c3">In Short:</span><span className="c4">&nbsp;Yes, if you are a resident of Virginia, you
                may be granted specific rights regarding access to and use of your personal information.</span></p>
            <p className="c0"><span className="c4">Virginia CDPA Privacy Notice</span></p>
            <p className="c0"><span className="c4">Under the Virginia Consumer Data Protection Act (CDPA):</span></p>
            <p className="c0"><span className="c4">&quot;Consumer&quot; means a natural person who is a resident of the Commonwealth
                acting only in an individual or household context. It does not include a natural person acting in a
                commercial or employment context.</span></p>
            <p className="c0"><span className="c4">&quot;Personal data&quot; means any information that is linked or reasonably linkable
                to an identified or identifiable natural person. &quot;Personal data&quot; does not include de-identified
                data or publicly available information.</span></p>
            <p className="c0"><span className="c4">&quot;Sale of personal data&quot; means the exchange of personal data for monetary
                consideration.</span></p>
            <p className="c0"><span className="c4">If this definition &quot;consumer&quot; applies to you, we must adhere to certain
                rights and obligations regarding your personal data.</span></p>
            <p className="c0"><span className="c4">The information we collect, use, and disclose about you will vary depending on how
                you interact with Lixone Inc. &nbsp;and our Services. To find out more, please visit the following
                links:</span></p>
            <ul className="c2 lst-kix_list_32-0 start">
                <li className="c1 li-bullet-0"><span className="c4">Personal data we collect </span></li>
                <li className="c1 li-bullet-0"><span className="c4">How we use &nbsp;your personal data </span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">When and with whom we share &nbsp;your personal data</span></li>
            </ul>
            <p className="c0"><span className="c24 c28">Your rights with respect to your personal data </span></p>
            <ul className="c2 lst-kix_list_33-0 start">
                <li className="c1 li-bullet-0"><span className="c4">Right to be informed whether or not we are processing your personal
                    data</span></li>
                <li className="c1 li-bullet-0"><span className="c4">Right to access your personal data</span></li>
                <li className="c1 li-bullet-0"><span className="c4">Right to correct inaccuracies in your personal data</span></li>
                <li className="c1 li-bullet-0"><span className="c4">Right to request deletion of your personal data</span></li>
                <li className="c1 li-bullet-0"><span className="c4">Right to obtain a copy of the personal data you previously shared
                    with us</span></li>
                <li className="c0 c17 li-bullet-0"><span className="c4">Right to opt out of the processing of your personal data if it
                    is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions
                    that produce legal or similarly significant effects (&quot;profiling&quot;)</span></li>
            </ul>
            <p className="c0" id="h.gjdgxs"><span className="c4">Lixone Inc. &nbsp;has not sold any personal data to third parties for
                business or commercial purposes. Lixone Inc. &nbsp;will not sell personal data in the future belonging to
                website visitors, users, and other consumers.</span></p>
            <p className="c0"><span className="c24 c28">Exercise your rights provided under the Virginia CDPA </span></p>
            <p className="c0"><span className="c4">More information about our data collection and sharing practices can be found in this
                privacy notice.</span></p>
            <p className="c0"><span>You may contact us by email at </span><span className="c25"><a className="c7"
                href="mailto:support@lixone.ca">support@lixone.ca</a></span><span className="c4">&nbsp;, by visiting
                    http://lixone.ca/privacy, or by referring to the contact details at the bottom of this document.</span></p>
            <p className="c0"><span className="c4">If you are using an authorized agent to exercise your rights, we may deny a request
                if the authorized agent does not submit proof that they have been validly authorized to act on your
                behalf.</span></p>
            <p className="c0"><span className="c24 c28">Verification process</span></p>
            <p className="c0"><span className="c4">We may request that you provide additional information reasonably necessary to verify
                you and your consumer&#39;s request. If you submit the request through an authorized agent, we may need to
                collect additional information to verify your identity before processing your request.</span></p>
            <p className="c0"><span className="c4">Upon receiving your request, we will respond without undue delay, but in all cases,
                within forty-five (45) days of receipt. The response period may be extended once by forty-five (45)
                additional days when reasonably necessary. We will inform you of any such extension within the initial
                45-day response period, together with the reason for the extension.</span></p>
            <p className="c0"><span className="c24 c28">Right to appeal</span></p>
            <p className="c0"><span>If we decline to take action regarding your request, we will inform you of our decision and
                reasoning behind it. If you wish to appeal our decision, please email us at </span><span className="c25"><a
                    className="c7" href="mailto:support@lixone.ca">support@lixone.ca</a></span><span className="c4">&nbsp;. Within
                        sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in
                        response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if
                        denied, you may contact the Attorney General to submit a complaint.</span></p>
            <p className="c0"><span className="c5 c3">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></p>
            <p className="c0"><span className="c4">In Short: Yes, we will update this notice as necessary to stay compliant with
                relevant laws.</span></p>
            <p className="c0"><span className="c4">We may update this privacy notice from time to time. The updated version will be
                indicated by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
                accessible. If we make material changes to this privacy notice, we may notify you either by prominently
                posting a notice of such changes or by directly sending you a notification. We encourage you to review this
                privacy notice frequently to be informed of how we are protecting your information.</span></p>
            <p className="c0"><span className="c5 c3">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p>
            <p className="c0"><span className="c4">If you have questions or comments about this notice, you may email us at
                support@lixone.ca</span></p>
            <p className="c0"><span className="c5 c3">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></p>
            <p className="c0"><span className="c4">Based on the applicable laws of your country, you may have the right to request
                access to the personal information we collect from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please contact support@lixone.ca</span></p>
            <p className="c0 c23"><span className="c4"></span></p>
        </div>
    )
}

export default Privacy;
